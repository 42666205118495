.home-section {
    text-align: center; /* テキストを中央寄せに設定 */
  }

  .home-image {
    width: 100%;  /* 画像の幅を親要素の100%に設定 */
    max-width: 100%;  /* 画像の最大幅を100%に設定 */
    height: auto;  /* 画像の高さは自動 */
    display: block;  /* 画像をブロック要素として表示 */
    margin: 0 auto;  /* 画像を中央寄せ */
}

@media (max-width: 800px) {
    .home-image {
        max-width: 90%;  
    }
}