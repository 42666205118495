
  
  .match-results {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .match {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    width: 300px;
    text-align: center;
  }


.games-title {
  font-size: 20px;
  font-weight: bold;
  color: #007BFF; /* タイトルも濃い青色に */
}

.games-content {
  font-size: 16px;
  margin-top: 10px;
  color: #007BFF; /* コンテンツのテキストも濃い青色に */
}

.games-content a {
  text-decoration: underline; /* リンクに下線を追加 */
}

.games-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; 
  text-align: center;
}

.games-article {
  width: 80%; 
  max-width: 800px; 
  padding: 15px;
  margin: 15px 0; 
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}




.highlighted-text {
  color: blue;
}


.trophy-image {
  margin-top: 15px;  /* 画像の上の余白を追加 (必要に応じて調整) */
}


.trophy-image {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.games-article a {
  position: relative;
  color: blue; /* または任意の色のコード */
}

Copy code
.games-article h3 {
  color: black; 
}