body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-section {
    text-align: center; /* テキストを中央寄せに設定 */
  }

  .home-image {
    width: 100%;  /* 画像の幅を親要素の100%に設定 */
    max-width: 100%;  /* 画像の最大幅を100%に設定 */
    height: auto;  /* 画像の高さは自動 */
    display: block;  /* 画像をブロック要素として表示 */
    margin: 0 auto;  /* 画像を中央寄せ */
}

@media (max-width: 800px) {
    .home-image {
        max-width: 90%;  
    }
}
.news-container {
  display: flex;
  flex-direction: column; /* 記事を縦に並べる */
  align-items: center; /* 記事を中央寄せ */
}

.news-article {
  width: 80%; /* 記事の幅を親コンテナの80%に設定 */
  max-width: 800px; /* 最大幅の設定（必要に応じて調整） */
  padding: 15px;
  margin: 15px 0; /* 上下にマージンを設定 */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px; /* 丸みを減らす */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.news-title {
  font-size: 20px;
  font-weight: bold;
}

.news-content {
  font-size: 16px;
  margin-top: 10px;
}

.calendar-container {
  text-align: center;
  margin-top: 50px;
}

.schedule-table {
  display: inline-block;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.schedule-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.schedule-table tr:hover {
  background-color: #f5f5f5;
}

.calendar-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px; /* 余白を追加 */
  padding-bottom: 100px; /* フッターの高さと同じ余白を追加 */
}

  
  .match-results {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .match {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    width: 300px;
    text-align: center;
  }


.games-title {
  font-size: 20px;
  font-weight: bold;
  color: #007BFF; /* タイトルも濃い青色に */
}

.games-content {
  font-size: 16px;
  margin-top: 10px;
  color: #007BFF; /* コンテンツのテキストも濃い青色に */
}

.games-content a {
  text-decoration: underline; /* リンクに下線を追加 */
}

.games-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; 
  text-align: center;
}

.games-article {
  width: 80%; 
  max-width: 800px; 
  padding: 15px;
  margin: 15px 0; 
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}




.highlighted-text {
  color: blue;
}


.trophy-image {
  margin-top: 15px;  /* 画像の上の余白を追加 (必要に応じて調整) */
}


.trophy-image {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.games-article a {
  position: relative;
  color: blue; /* または任意の色のコード */
}

Copy code
.games-article h3 {
  color: black; 
}
.members-section {
    text-align: center;
    padding-bottom: 100px;
  }
  
  .grade-section {
    margin-bottom: 2rem;
  }
  
  .member {
    display: inline-block;
    width: 30%;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 20px; /* 丸っぽい枠 */
    border: 2px solid #000; /* 枠線 */
    box-sizing: border-box;
  }
  
  .member p {
    margin: 0;
  }
  
  /* 画面サイズが小さい場合に対応するためのメディアクエリ */
  @media screen and (max-width: 768px) {
    .member {
      width: 100%;
    }
  }
  
  .member img {
    width: 150px;
    height: 200px;
    object-fit: cover; /* 画像のアスペクト比を保持しつつ、コンテナにフィットさせる */
}
.join-container {
    text-align: center;
    margin-top: 50px;
  }
  
.ob-og-section {
    text-align: center; /* テキストを中央寄せに設定 */
  }
  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

nav {
  display: flex;
  justify-content: space-around;
}

a {
  text-decoration: none;
  color: inherit;
  padding: 15px;
}

@media (max-width: 768px) { /* 768px以下のデバイスの場合に適用されるスタイル */
  .navbar a {
    margin: 0 5px; /* マージンを半分に調整 */
    padding: 7px;  /* パディングを半分に調整 */
  }
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* 背景色を設定（任意の色に変更可） */
  padding: 10px;
  border: 1px solid #d0d0d0; /* 境界線を設定 */
}

.navbar a {
  margin: 0 10px;
  text-decoration: none;
  color: #333; /* テキストの色を設定（任意の色に変更可） */
}

.home-section {
  text-align: center; /* テキストを中央寄せに設定 */
}

.social-link img {
  width: 30px; /* アイコンのサイズを30ピクセルに設定 */
  margin-left: 10px; /* アイコン間の左側のマージンを10ピクセルに設定 */
}


.footer {
  background-color: #f5f5f5;
  padding-top: 50px;
  text-align: center;
  width: 100%; 
  position: fixed;
  bottom: 0;
}

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 140px; /* footerの高さとパディングの合計に応じて調整する */
}


.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

Router {
  flex: 1 1;
}



@media (max-width: 768px) {
  h1, h2, h3, h4, h5, h6, p, a {
      padding-left: 15px;
      padding-right: 15px;
  }
  p, a {
      word-wrap: break-word;
  }
}

