.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  display: flex;
  justify-content: space-around;
}

a {
  text-decoration: none;
  color: inherit;
  padding: 15px;
}

@media (max-width: 768px) { /* 768px以下のデバイスの場合に適用されるスタイル */
  .navbar a {
    margin: 0 5px; /* マージンを半分に調整 */
    padding: 7px;  /* パディングを半分に調整 */
  }
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* 背景色を設定（任意の色に変更可） */
  padding: 10px;
  border: 1px solid #d0d0d0; /* 境界線を設定 */
}

.navbar a {
  margin: 0 10px;
  text-decoration: none;
  color: #333; /* テキストの色を設定（任意の色に変更可） */
}

.home-section {
  text-align: center; /* テキストを中央寄せに設定 */
}

.social-link img {
  width: 30px; /* アイコンのサイズを30ピクセルに設定 */
  margin-left: 10px; /* アイコン間の左側のマージンを10ピクセルに設定 */
}


.footer {
  background-color: #f5f5f5;
  padding-top: 50px;
  text-align: center;
  width: 100%; 
  position: fixed;
  bottom: 0;
}

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 140px; /* footerの高さとパディングの合計に応じて調整する */
}


.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

Router {
  flex: 1;
}



@media (max-width: 768px) {
  h1, h2, h3, h4, h5, h6, p, a {
      padding-left: 15px;
      padding-right: 15px;
  }
  p, a {
      word-wrap: break-word;
  }
}
