.news-container {
  display: flex;
  flex-direction: column; /* 記事を縦に並べる */
  align-items: center; /* 記事を中央寄せ */
}

.news-article {
  width: 80%; /* 記事の幅を親コンテナの80%に設定 */
  max-width: 800px; /* 最大幅の設定（必要に応じて調整） */
  padding: 15px;
  margin: 15px 0; /* 上下にマージンを設定 */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px; /* 丸みを減らす */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.news-title {
  font-size: 20px;
  font-weight: bold;
}

.news-content {
  font-size: 16px;
  margin-top: 10px;
}
