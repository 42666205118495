.members-section {
    text-align: center;
    padding-bottom: 100px;
  }
  
  .grade-section {
    margin-bottom: 2rem;
  }
  
  .member {
    display: inline-block;
    width: 30%;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 20px; /* 丸っぽい枠 */
    border: 2px solid #000; /* 枠線 */
    box-sizing: border-box;
  }
  
  .member p {
    margin: 0;
  }
  
  /* 画面サイズが小さい場合に対応するためのメディアクエリ */
  @media screen and (max-width: 768px) {
    .member {
      width: 100%;
    }
  }
  
  .member img {
    width: 150px;
    height: 200px;
    object-fit: cover; /* 画像のアスペクト比を保持しつつ、コンテナにフィットさせる */
}