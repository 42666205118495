.calendar-container {
  text-align: center;
  margin-top: 50px;
}

.schedule-table {
  display: inline-block;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.schedule-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.schedule-table tr:hover {
  background-color: #f5f5f5;
}

.calendar-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px; /* 余白を追加 */
  padding-bottom: 100px; /* フッターの高さと同じ余白を追加 */
}